<template>
  <div class="container-cental">
    <h1 class="mt-5">{{ $t("messages.detailAppointment").toUpperCase() }}</h1>
    <b-form ref="formAdd">
      <div class="row p-3">
        <div class="col-12 header-section">{{ $t("messages.user").toUpperCase() }}</div>
        <!-- Nombres -->
        <b-form-group id="nombres_usuario" :label="$t('messages.firstnames') + ':'" label-for="nombres_usuario"
          class="col-md-3">
          <b-form-input id="nombres_usuario" v-model.trim="form.nombres_usuario" type="text"
            :placeholder="$t('messages.firstnames')" ref="nombres_usuario" :disabled="true"></b-form-input>
        </b-form-group>
        <!-- apellidos -->
        <b-form-group id="apellidos_usuario" :label="$t('messages.lastnames') + ':'" label-for="apellidos_usuario"
          class="col-md-3">
          <b-form-input id="apellidos_usuario" v-model.trim="form.apellidos_usuario" type="text"
            :placeholder="$t('messages.lastnames')" ref="apellidos_usuario" :disabled="true"></b-form-input>
        </b-form-group>
        <!-- email -->
        <b-form-group id="email_usuario" label="Email:" label-for="email_usuario" class="col-md-3">
          <b-form-input id="email_usuario" v-model.trim="form.email_usuario" type="text" placeholder="email"
            ref="email_usuario" :disabled="true"></b-form-input>
        </b-form-group>
        <!-- telefono -->
        <b-form-group id="telefono_usuario" :label="$t('messages.phone') + ':'" label-for="telefono_usuario"
          class="col-md-3">
          <b-form-input id="telefono_usuario" v-model.trim="form.telefono_usuario" type="text"
            :placeholder="$t('messages.phone')" ref="telefono_usuario" :disabled="true"></b-form-input>
        </b-form-group>
        <!-- Zona Horaria -->
        <b-form-group id="zona_horaria" :label="$t('messages.timeZone') + ':'" label-for="zona_horaria" class="col-md-3">
          <b-form-input id="zona_horaria" v-model.trim="form.zona_horaria" type="text"
            :placeholder="$t('messages.timeZone')" ref="zona_horaria" :disabled="true"></b-form-input>
        </b-form-group>
        <div class="col-12 header-section">
          {{ $t("messages.channeler").toUpperCase() }}
        </div>
        <!-- Nombres Canalizadora -->
        <b-form-group id="nombre_sesionista" :label="$t('messages.firstnames') + ':'" label-for="nombre_sesionista"
          class="col-md-3">
          <b-form-input id="nombre_sesionista" v-model.trim="form.nombre_sesionista" type="text"
            :placeholder="$t('messages.firstnames')" ref="nombre_sesionista" :disabled="true"></b-form-input>
        </b-form-group>
        <!-- Apellidos Canalizadora -->
        <b-form-group id="apellido_sesionista" :label="$t('messages.lastnames') + ':'" label-for="apellido_sesionista"
          class="col-md-3">
          <b-form-input id="apellido_sesionista" v-model.trim="form.apellido_sesionista" type="text"
            :placeholder="$t('messages.lastnames')" ref="apellido_sesionista" :disabled="true"></b-form-input>
        </b-form-group>
        <!-- Email Canalizadora -->
        <b-form-group id="email_sesionista" :label="$t('messages.email') + ':'" label-for="email_sesionista"
          class="col-md-3">
          <b-form-input id="email_sesionista" v-model.trim="form.email_sesionista" type="text"
            :placeholder="$t('messages.email')" ref="email_sesionista" :disabled="true"></b-form-input>
        </b-form-group>
        <div class="col-12 header-section">
          {{ $t("messages.session").toUpperCase() }}
        </div>
        <!-- tipo_sesion -->
        <b-form-group id="nombre_sesion" :label="$t('messages.typeOfSession') + ':'" label-for="nombre_sesion"
          class="col-md-3">
          <b-form-input id="nombre_sesion" v-model.trim="form.nombre_sesion" type="text"
            :placeholder="$t('messages.typeOfSession')" ref="nombre_sesion" :disabled="true"></b-form-input>
        </b-form-group>
        <!-- Fecha y hora local del usuario -->
        <b-form-group id="fecha_hora_local" :label="$t('messages.dateLocalTime') + ':'" label-for="fecha_hora_local"
          class="col-md-3">
          <b-form-input id="fecha_hora_local" :value="fecha_hora_local" type="text"
            :placeholder="$t('messages.dateLocalTime')" ref="fecha_hora_local" :disabled="true"></b-form-input>
        </b-form-group>
        <!-- Fecha y hora local del canalizador -->
        <b-form-group id="fecha_hora_canalizador" :label="$t('messages.dateAndTimeOfTheChanneler') + ':'"
          label-for="fecha_hora_canalizador" class="col-md-3">
          <b-form-input id="fecha_hora_canalizador" :value="fecha_hora_canalizador" type="text"
            :placeholder="$t('messages.dateAndTimeOfTheChanneler')" ref="fecha_hora_canalizador"
            :disabled="true"></b-form-input>
        </b-form-group>
        <!-- comentarios -->
        <b-form-group id="comentario" :label="$t('messages.comentario') + ':'" label-for="comentario" class="col-md-3">
          <b-form-input id="comentario" v-model.trim="form.comentario" type="text"
            :placeholder="$t('messages.comentario')" ref="comentario" :disabled="true"></b-form-input>
        </b-form-group>

        <!-- locacion -->
        <b-form-group id="locacion" :label="`${$t('messages.location')}:`" label-for="locacion" class="col-md-3">
          <b-form-select id="location" v-model="form.locacion" :options="localizacionOpciones" style="width: 100%;"
            ref="locacion" :disabled="true"></b-form-select>
        </b-form-group>

        <!-- Lugar de encuentro -->
        <b-form-group id="sitio_de_encuentro" :label="$t('messages.meetingPlace') + ':'" label-for="sitio_de_encuentro"
          class="col-md-3">
          <b-form-input id="sitio_de_encuentro" v-model.trim="form.sitio_de_encuentro" type="text"
            :placeholder="$t('messages.meetingPlace')" ref="sitio_de_encuentro" :disabled="true"></b-form-input>
        </b-form-group>
        <!-- Estado -->
        <b-form-group id="estado" :label="$t('messages.status') + ':'" label-for="input-3" class="col-md-3">
          <b-form-select id="estado" v-model="form.estado" :options="estados" ref="estado" :state="estadoState"
            :disabled="true"></b-form-select>
        </b-form-group>
      </div>
      <b-button variant="danger" class="ml-3" @click="$router.push({ name: 'AgendamientosUser' })">{{ $t("messages.close")
      }}</b-button>
    </b-form>
  </div>
</template>

<script>
import CalendarioService from "@/services/calendario.service";
import SesionesService from "@/services/sesiones.service";
import { Helpers } from "@/mixins/Helpers";
export default {
  name: "DetalleAgendamientoCliente",
  mixins: [Helpers],
  data() {
    return {
      calendarioService: null,
      sesionesService: null,
      estadoState: null,
      type: "edit",
      form: {
        id: 0,
        id_usuario: 0,
        id_sesionista: 0,
        dia: 0,
        mes: 0,
        anio: 0,
        hora: 0,
        id_tipo_sesion: 0,
        comentario: "",
        estado: 0,
        sitio_de_encuentro: "",
        zona_horaria: "",
        created: "",
        updated: "",
        status: 0,
        nombres_usuario: "",
        apellidos_usuario: "",
        email_usuario: "",
        telefono_usuario: "",
        nombre_sesionista: "",
        apellido_sesionista: "",
        nombre_sesion: "",
        descripcion: "",
        id_huso_horario: 0,
        duracion: 90,
      },
      husoHorarios: [],
      fechaSeleccionada: null,
      horaSeleccionada: null,
      horasDisponibles: [],
    };
  },
  created() {
    this.calendarioService = new CalendarioService();
    this.sesionesService = new SesionesService();
    this.$route.params.id
      ? (this.type = "edit")
      : this.$router.push({
        name: "Home",
      });
  },
  mounted() {
    this.getHusoHorarios();
    this.recoveryDataRegister();
    this.getHorasDisponibles();
  },
  methods: {
    getHorasDisponibles() {
      this.horasDisponibles = [];
      const initialHour = 0;
      const finalHour = 81000;
      const interval = 600;
      for (let i = initialHour; i < finalHour; i += interval) {
        this.horasDisponibles.push({
          text: this.secondsToHM(i),
          value: i,
        });
      }
    },
    getHusoHorarios() {
      this.sesionesService
        .getHusoHorarios()
        .then((response) => {
          this.husoHorarios = response.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    recoveryDataRegister() {
      this.calendarioService
        .getAgendamientosByIdForCliente(this.$route.params.id)
        .then((result) => {
          if (!result.data.data) {
            this.$router.push({
              name: "Home",
            });
          }
          this.form = { ...result.data.data };
          if (this.form.telefono_usuario && this.form.telefono_usuario.toString().length > 0 && this.form.telefono_usuario.toString()[0] != "+") {
            this.form.telefono_usuario = "+" + this.form.telefono_usuario;
          }
          this.form.apellido_sesionista = this.allFirstLettersUpperCase(this.form.apellido_sesionista);
          this.form.apellidos_usuario = this.allFirstLettersUpperCase(this.form.apellidos_usuario);
          this.form.nombres_usuario = this.allFirstLettersUpperCase(this.form.nombres_usuario);
          this.form.nombre_sesionista = this.allFirstLettersUpperCase(this.form.nombre_sesionista);
          this.form.nombre_sesionista = this.allFirstLettersUpperCase(this.form.nombre_sesionista);
          this.form.nombre_sesion = this.$t("nav." + this.form.codigo);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    fecha_hora_local() {
      if (!this.form.anio) return "";
      const dateString = `${this.form.anio}-${this.form.mes < 10
          ? "0" + this.form.mes.toString()
          : this.form.mes.toString()
        }-${this.form.dia - 1 < 10
          ? "0" + this.form.dia.toString()
          : this.form.dia.toString()
        } ${this.secondsToHM(this.form.hora)}`;

      const a = this.$moment.tz(dateString, this.form.zona_horaria);
      return this.firstLetterToUpperCase(a.format("dddd, DD-MM-YYYY HH:mm"));
    },
    fecha_hora_canalizador() {
      if (
        !this.form.id_huso_horario ||
        !this.form.anio ||
        this.husoHorarios.length == 0 ||
        !this.form.zona_horaria
      )
        return "";

      const huso_horario = this.husoHorarios.find(
        (husoHorario) => husoHorario.id === this.form.id_huso_horario
      );

      const dateString = `${this.form.anio}-${this.form.mes < 10
          ? "0" + this.form.mes.toString()
          : this.form.mes.toString()
        }-${this.form.dia - 1 < 10
          ? "0" + this.form.dia.toString()
          : this.form.dia.toString()
        } ${this.secondsToHM(this.form.hora)}`;

      const local = this.$moment.tz(dateString, this.form.zona_horaria);

      const a = local.clone().tz(huso_horario.timezone);

      return this.firstLetterToUpperCase(a.format("dddd, DD-MM-YYYY HH:mm"));
    },
    hora_canalizador() {
      if (
        !this.form.id_huso_horario ||
        this.husoHorarios.length == 0 ||
        !this.fechaSeleccionada ||
        !this.horaSeleccionada
      )
        return "";

      const huso_horario = this.husoHorarios.find(
        (husoHorario) => husoHorario.id === this.form.id_huso_horario
      );

      const local = this.$moment.tz(
        `${this.fechaSeleccionada} ${this.secondsToHM(this.horaSeleccionada)}`,
        this.form.zona_horaria
      );

      const a = local.clone().tz(huso_horario.timezone);
      return a.format("HH:mm");
    },
    estados() {
      return [
        { text: this.$t("messages.proposed"), value: 1 },
        { text: this.$t("messages.accepted"), value: 2 },
        { text: this.$t("messages.rejected"), value: 3 },
        { text: this.$t("messages.canceled"), value: 4 },
        { text: this.$t("messages.finished"), value: 5 },
      ];
    },
    localizacionOpciones() {
      return [
        { value: 'inperson', text: this.$t("messages.inperson") },
        { value: 'online', text: this.$t("messages.online") }
      ]
    },
  },
};
</script>

<style lang="scss" scoped>
.container-cental {
  width: 100%;
  min-height: 700px;
  padding-bottom: 30px;
}

.header-section {
  text-align: left;
  border-bottom: 1px solid #bfbfbf;
  margin-bottom: 25px;
  font-weight: 900;
  font-size: 1.2rem;

  &:not(:first-child) {
    margin-top: 30px;
  }
}

.myclass {
  font-size: 80px;
  font-weight: bold;
}

.calendar_default_event_inner {
  background: #2e78d6;
  color: white;
  border-radius: 5px;
  opacity: 0.9;
}

@media screen and (max-width: 1500px) {
  .header-section {
    margin-bottom: 15px;
    font-size: 1rem;

    &:not(:first-child) {
      margin-top: 10px;
    }
  }

  .mb-5 {
    margin-bottom: 0px !important;
  }
}
</style>
